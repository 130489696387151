class PageMenuService {
    static async fetchMenu(code, table) {
        const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;
        const response = await fetch(`${apiUrl}/pageMenu/${navigator.language}/${code}/${table}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }
  }
  
  export default PageMenuService;
  