import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Usamos useLocation

import MenuList from './components/menu/MenuList';
import PageMenuService from './services/PageMenuService';

const MenuListPage = () => {
  const [pageMenu, setPageMenu] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Usamos useLocation para obtener los parámetros de consulta
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Obtener los parámetros de la URL

  const code = queryParams.get('code');  // Obtener el valor de 'code'
  const table = queryParams.get('table');  // Obtener el valor de 'table'

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const data = await PageMenuService.fetchMenu(code, table); // Llamada a la API con los parámetros
        setPageMenu(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (code && table) {
      fetchMenuData();  // Ejecutar solo si 'code' y 'table' están disponibles
    }
  }, [code, table]);  // Re-fetch cuando los parámetros cambian

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <MenuList pageMenu={pageMenu} />
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MenuListPage />} /> {/* Ruta raíz */}
      </Routes>
    </Router>
  );
}

export default App;
