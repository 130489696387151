import React from 'react';
import Header from './list/header/Header';
import Banner from './list/banner/Banner';
import Footer from './list/footer/Footer';
import Menu from './list/menu/MenuProduct';

function MenuList({ pageMenu }) {
    const customer = pageMenu?.customer;
    const header = pageMenu?.header;
    const banner = pageMenu?.banner;
    const menu = pageMenu?.menu;
    const footer = pageMenu?.footer;

    return (
        <div>
            <Header customer={customer} header={header} />
            <Banner banner={banner} />
            <Menu menu={menu} />
            <div className="top-scrolling">
                <a href="#header" className="scrollTo">
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                </a>
            </div>
            <Footer customer={customer} footer={footer} />
        </div>
    );
}

export default MenuList;
