import React from 'react';

const Menu = ({ menu }) => {
  return (
    <section className="menu-list pt-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="menu-tabbing">
              <ul id="tabs" className="nav nav-tabs" role="tablist">
                {/* Pestaña "All" para mostrar todos los productos */}
                <li role="presentation" className="tab-link current" data-tab="tab-all">
                  <a href="#tab-all" role="tab" data-toggle="tab" className="active">
                    All
                  </a>
                </li>

                {/* Mapeamos las categorías existentes */}
                {menu.category.map((category) => (
                  <li key={category.refId} role="presentation" className="tab-link" data-tab={category.refId}>
                    <a href={`#${category.refId}`} role="tab" data-toggle="tab">
                      {category.description}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Contenido de las pestañas */}
        <div className="tab-content">
          {/* Mostrar todos los productos bajo "All" */}
          <div role="tabpanel" className="row tab-pane fade in active show" id="tab-all">
            {menu.products.map((productCategory) =>
              productCategory.items.map((item) => (
                <div className="col-xl-6 col-lg-6 col-md-6" key={item.id}>
                  <div className="menu-list-box-2">
                    <div className="list-img-2">
                      <a href="shop-detail.html">
                        <img src={item.productImage} alt="product" />
                      </a>
                    </div>
                    <div className="menu-detail-2">
                      <div className="iteam-name-list">
                        <a href="shop-detail.html" className="iteam-name">
                          {item.name}
                        </a>
                        <span className="iteam-srice">
                          {item.price.toFixed(2)} {item.currency}
                        </span>
                      </div>
                      <p className="iteam-desc">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Mostrar productos por categoría */}
          {menu.products.map((productCategory) => (
            <div
              role="tabpanel"
              className="row tab-pane fade"
              id={productCategory.category}
              key={productCategory.category}
            >
              {productCategory.items.map((item) => (
                <div className="col-xl-6 col-lg-6 col-md-6" key={item.id}>
                  <div className="menu-list-box-2">
                    <div className="list-img-2">
                      <a href="shop-detail.html">
                        <img src={item.productImage} alt="product" />
                      </a>
                    </div>
                    <div className="menu-detail-2">
                      <div className="iteam-name-list">
                        <a href="shop-detail.html" className="iteam-name">
                          {item.name}
                        </a>
                        <span className="iteam-srice">
                          {item.price.toFixed(2)} {item.currency}
                        </span>
                      </div>
                      <p className="iteam-desc">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Menu;
