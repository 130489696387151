import React from 'react';

const Footer = ({ customer, footer }) => {
    return (
        <footer>
            <div className="container">
                <div className="footer">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 footer-box">
                            <div className="footer-logo">
                                <img alt="logo" src={customer?.logoImage} />
                                <p className="footer-des">
                                    {customer.address}
                                </p>
                                <ul>
                                    <li>phone  - <a href="#">{customer.phone}</a></li>
                                    <li>email  - <a href="#"><span className="__cf_email__">{customer.email}</span></a></li>
                                </ul>

                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 footer-box">
                            <div className="opening-hours">
                                <h2>{footer.openingHours}</h2>
                                <ul>
                                    <li>
                                        Segunda-feira:
                                        {customer.mondayIsOpen ? (
                                            <span>{customer.mondayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                    <li>
                                        Terça-feira:
                                        {customer.tuesdayIsOpen ? (
                                            <span>{customer.tuesdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                    <li>
                                        Quarta-feira:
                                        {customer.wednesdayIsOpen ? (
                                            <span>{customer.wednesdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                    <li>
                                        Quinta-feira:
                                        {customer.thursdayIsOpen ? (
                                            <span>{customer.thursdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                    <li>
                                        Sexta-feira:
                                        {customer.fridayIsOpen ? (
                                            <span>{customer.fridayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                    <li>
                                        Sábado:
                                        {customer.saturdayIsOpen ? (
                                            <span>{customer.saturdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                    <li>
                                        Domingo:
                                        {customer.sundayIsOpen ? (
                                            <span>{customer.sundayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                    <li>
                                        Feriados:
                                        {customer.holidayIsOpen ? (
                                            <span>{customer.holidayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close">Fechado</span>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className="col-xl-4 col-lg-4 col-md-4 footer-box">
                            <div className="useful-links">
                                <h2>Useful Links</h2>
                                <ul>
                                    {/* Solo muestra el enlace About si aboutEnabled es true */}
                                    {footer.aboutEnabled && (
                                        <li>
                                            <a href="about.html">{footer.aboutText}</a>
                                        </li>
                                    )}
                                    {/* Solo muestra el enlace Contact si contactEnabled es true */}
                                    {footer.contactEnabled && (
                                        <li>
                                            <a href="contact.html">{footer.contactText}</a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 copyright-box">
                            <p className="copy-text">©Copyright <a href="">{customer.displayName}</a> {footer.legalText}</p>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 copyright-box">
                            <ul>
                                {/* Muestra el icono de Facebook si está habilitado */}
                                {customer.facebook && (
                                    <li>
                                        <a href={customer.facebookLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}

                                {/* Muestra el icono de Twitter (X) si está habilitado */}
                                {customer.x && (
                                    <li>
                                        <a href={customer.xlink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}

                                {/* Muestra el icono de LinkedIn si está habilitado */}
                                {customer.linkedin && (
                                    <li>
                                        <a href={customer.linkedinLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}

                                {/* Muestra el icono de Instagram si está habilitado */}
                                {customer.instagram && (
                                    <li>
                                        <a href={customer.instagramLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
