import React from 'react';

const Header = ({ customer, header }) => {
    return (
        <header id="header">
            <div className="container">
                <div className="row m-0">
                    <div className="col-xl-3 col-lg-2 col-md-4 col-3 p-0">
                        <div className="navbar-header">
                            <a className="navbar-brand page-scroll" href="#">
                                <img alt="logo" src={ customer?.logoImage }  />
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-10 col-md-8 col-9 p-0 text-right">
                        <div className="header-right-link">
                            <ul>
                                <li className="cart-icon">
                                    <a href="#">
                                        <span className="icon" style={{ color: '#0000FF' }}></span>
                                        <div className="link-text">{ header?.cartItems } items - <span>{ header?.cartValue.toFixed(2) }</span></div>
                                    </a>
                                    <div className="cart-dropdown header-link-dropdown">
                                        <ul className="cart-list link-dropdown-list">
                                            {/* Cart Items */}
                                            {[
                                                { name: 'Margherita Pizza', img: 'images/1.png', price: '$14.99' },
                                                { name: 'Greek Pizza', img: 'images/2.png', price: '$14.99' },
                                            ].map((item, index) => (
                                                <li key={index}>
                                                    <a className="close-cart"><i className="fa fa-times-circle"></i></a>
                                                    <div className="media">
                                                        <a href="shop-detail.html" className="pull-left">
                                                            <img alt="Pizzon" src={item.img} />
                                                        </a>
                                                        <div className="media-body">
                                                            <span><a href="shop-detail.html">{item.name}</a></span>
                                                            <p className="cart-price">{item.price}</p>
                                                            <div className="product-qty">
                                                                <label>Qty:</label>
                                                                <div className="custom-qty">
                                                                    <input type="text" name="qty" maxLength="8" value="1" title="Qty" className="input-text qty" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <p className="cart-sub-totle">
                                            <span className="pull-left">Cart Subtotal</span>
                                            <span className="pull-right"><strong className="price-box">$29.98</strong></span>
                                        </p>
                                        <div className="clearfix"></div>
                                        <div className="mt-20 text-left">
                                            <a href="cart.html" className="btn-color btn">Cart</a>
                                            <a href="checkout.html" className="btn-color btn right-side">Checkout</a>
                                        </div>
                                    </div>
                                </li>
                                <li className="order-online">
                                    <a href="shop-categories.html" className="btn btn-green">{ header?.mainButton }</a>
                                </li>
                                {/*
                                <li className="side-toggle">
                                    <button data-target=".navbar-collapse" data-toggle="collapse" className="navbar-toggle" type="button">
                                        <span></span>
                                    </button>
                                </li>
                                */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
