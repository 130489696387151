import React from 'react';

const Banner = ({banner}) => {
  
  return (
    <section className="page-banner" style={{ background: `#121619 url(${banner.backgroundImage}) no-repeat center / cover` }}>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="page-title">
              <h1 className="page-headding">{ banner?.pageTitle }</h1>
              <ul>
                <li>{/*<a href="#" className="page-name">Home</a>*/} { banner?.link1 }</li>
                <li>{ banner?.link2 }</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
